<template>
  <v-col>
    <v-row no-gutters>
      <v-col class="mb-6 text-h6" cols="8">
        {{ userData.firstName + ' ' + userData.lastName }}
      </v-col>
      <v-col align="right" v-if="$vuetify.breakpoint.smAndUp">
        <v-btn
          @click="$emit('toggleIsDialogVisible', 'top')"
          v-if="!isEditingDisabled"
          text
          color="msaBlue"
          class="px-1 ma-0"
        >
          EDIT
        </v-btn>
      </v-col>
    </v-row>

    <v-row :class="iconTextRowSpacing">
      <v-col class="shrink">
        <v-icon color="black">mdi-account-outline</v-icon>
      </v-col>

      <v-col
        class="grow"
        :class="{ 'black--text font-weight-medium': title != 'Title' }"
        :style="{ 'word-break': 'break-word' }"
      >
        {{ title }}
      </v-col>
    </v-row>

    <v-row :class="iconTextRowSpacing">
      <v-col cols="auto">
        <v-icon color="black">mdi-phone-outline</v-icon>
      </v-col>

      <v-col
        :class="{
          'black--text font-weight-medium': phoneNumber != 'Phone Number',
        }"
      >
        {{ phoneNumber }}
      </v-col>
    </v-row>

    <v-row :class="iconTextRowSpacing" class="d-flex nowrap">
      <v-col cols="auto" class="d-flex nowrap">
        <v-icon color="black">mdi-email-outline</v-icon>
      </v-col>

      <v-col :class="{ 'black--text font-weight-medium': email != 'Email' }">
        {{ email }}
      </v-col>
    </v-row>
    <v-row :class="iconTextRowSpacing">
      <v-col cols="auto">
        <v-icon color="black">mdi-calendar-check-outline</v-icon>
      </v-col>

      <v-col
        :class="{ 'black--text font-weight-medium': hireDate != 'Hire Date' }"
      >
        {{ hireDate }}
      </v-col>
    </v-row>

    <v-row
      v-for="inputField in userData.userCustomFields"
      :key="inputField.id"
      :class="iconTextRowSpacing"
    >
      <v-col cols="auto">
        <v-icon color="black">mdi-pencil-box-outline</v-icon>
      </v-col>
      <v-col
        :class="{
          'black--text font-weight-medium': inputField.value.length,
        }"
      >
        {{
          !inputField.value.length
            ? inputField.name
            : inputField.name + ': ' + inputField.value
        }}
      </v-col>
    </v-row>

    <v-row :class="iconTextRowSpacing">
      <v-col cols="auto">
        <v-icon color="black">mdi-calendar-account-outline</v-icon>
      </v-col>
      <v-col class="black--text font-weight-medium">
        Added: {{ userData.createdAt }}
      </v-col>
    </v-row>

    <v-row class="align-center mt-4" v-if="!isUserProfile">
      <v-col class="shrink">
        <label>Status</label>
      </v-col>

      <v-col class="grow">
        <v-chip :color="chipColour">
          <v-switch
            dense
            :true-value="1"
            :false-value="0"
            @change="promptStatusChange()"
            v-model="userData.status"
            :color="switchColour"
            :readonly="isSwitchDisabled"
          />
          <label :class="labelTextColour">
            {{ switchLabel }}
          </label>
        </v-chip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'UserInformation',
  props: {
    userInformation: Object,
    iconTextRowSpacing: String,
    isUserProfile: Boolean,
    isEditingDisabled: Boolean,
  },
  data() {
    return {
      userData: {
        title: '',
        status: 0, // This is used over a prop like isUserDisabled to allow for switch to toggle if user cancels disabling status of user
        email: '',
        phone: '',
        lastName: '',
        hireDate: '',
        firstName: '',
        createdAt: '',
        isWebAdministrator: -1,
      },
      styles: {
        linkColour: 'msaBlue--text',
      },
    };
  },
  computed: {
    title() {
      if (this.userData.title == '') {
        return 'Title';
      }

      return this.userData.title;
    },
    phoneNumber() {
      if (this.userData.phone == '') {
        return 'Phone Number';
      }

      return this.userData.phone;
    },
    email() {
      if (this.userData.email == '') {
        return 'Email';
      }

      return this.userData.email;
    },
    hireDate() {
      if (this.userData.hireDate == '') {
        return 'Hire Date';
      }

      return 'Hired: ' + this.userData.hireDate;
    },
    isSwitchDisabled() {
      if (this.$store.getters.user.id == this.userData.id) {
        return true;
      }

      return false;
    },
    switchLabel() {
      return this.userData.status ? 'Active' : 'Inactive';
    },
    chipColour() {
      return this.userData.status && !this.isSwitchDisabled
        ? 'green'
        : 'lightGrey';
    },
    switchColour() {
      return this.userData.status && !this.isSwitchDisabled
        ? 'grey-lighten-5'
        : 'grey-lighten-1';
    },
    labelTextColour() {
      return this.userData.status && !this.isSwitchDisabled
        ? 'white--text'
        : 'black--text';
    },
  },
  methods: {
    initData() {
      this.userData = JSON.parse(JSON.stringify(this.userInformation));
    },
    promptStatusChange() {
      // Inactive is 0 but because switch it bound to status,
      // that means 1 is inactive because the value was toggled
      if (this.userData.status == 1) {
        this.confirmStatusChange();
        return;
      }

      this.$root.showMessage(
        'Confirm',
        'Are you sure? Deactivating a user will unassign all items and remove the groups.',
        () => this.confirmStatusChange(),
        () => this.toggleUserActivity(),
        'Confirm',
        'Cancel',
        true,
      );
    },
    confirmStatusChange() {
      const params = {
        userId: this.userData.id,
        status: this.userData.status,
        loaderText: 'Updating status...',
      };
      this.$axios
        .post('update-user-status?format=json', params)
        .then(() => {
          this.$emit('refresh');
        })
        .catch(() => {
          this.userData.status = 0;
        });
    },
    toggleUserActivity() {
      const active = 1;
      const inactive = 0;

      this.userData.status = this.userData.status == active ? inactive : active;
    },
  },
  watch: {
    userInformation: {
      handler(val) {
        if (val) {
          this.initData();
        }
      },
      deep: true,
    },
  },
};
</script>
